import React from "react";

const FooterNav = () => {
  return (
    <nav>
      <ul className="nav justify-content-center text-uppercase">
        <li className="nav-link">
          <a
            href="https://www.mars.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            سياسة الخصوصية
            <span className="visually-hidden">opens in a new window</span>
          </a>
        </li>
        <li className="nav-link">
          <a
            href="https://www.mars.com/cookies-english"
            target="_blank"
            rel="noopener noreferrer"
          >
            سجلّ المتصفّح
            <span className="visually-hidden">opens in a new window</span>
          </a>
        </li>
        <li className="nav-link">
          <a
            href="https://www.mars.com/legal"
            target="_blank"
            rel="noopener noreferrer"
          >
            قانون
            <span className="visually-hidden">opens in a new window</span>
          </a>
        </li>
        <li className="nav-link">
          <a
            href="https://www.mars.com/about/policies-and-practices/ca-supply-chain-transparency-act"
            target="_blank"
            rel="noopener noreferrer"
          >
            قانون ولاية كاليفورنيا للشفافية في سلسلة التوريد
            <span className="visually-hidden">opens in a new window</span>
          </a>
        </li>
        <li className="nav-link">
          <a
            href="https://www.mars.com/accessibility"
            target="_blank"
            rel="noopener noreferrer"
          >
            تيسير الوصول
            <span className="visually-hidden">opens in a new window</span>
          </a>
        </li>
        <li className="nav-link">
          <a
            href="https://www.mars.com/about/policies-and-practices/modern-slavery-act"
            target="_blank"
            rel="noopener noreferrer"
          >
            قانون العبودية الحديثة
            <span className="visually-hidden">opens in a new window</span>
          </a>
        </li>
        <li className="nav-link">
          <a
            href="https://www.mars.com/mars-incorporated-adchoices-united-states"
            target="_blank"
            rel="noopener noreferrer"
          >
            تحديد الإعلانات
            <span className="visually-hidden">opens in a new window</span>
          </a>
        </li>
        <li className="nav-link">
          <a
            href="https://www.mars.com/contact-us"
            target="_blank"
            rel="noopener noreferrer"
          >
            تواصلوا معنا
            <span className="visually-hidden">opens in a new window</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;
