import React, { useEffect } from "react";
import FooterNav from "./footerNavAr";
import Copyright from "./copyrightAr";

const Footer = () => {

  const footerElement = typeof window !== 'undefined' && document.querySelector('.main-footer');

   useEffect(() => {
    if(footerElement){
      const parentElement = footerElement.parentNode;
      parentElement.style.height = 'unset';
      // parentElement.style.background = 'white';
      parentElement.style.paddingBottom = '40px';
      parentElement.style.paddingTop = '40px';
    }
   }, [footerElement])

  return (
    <footer className="main-footer" id="footer">
      <div className="container">
        <FooterNav />
        <Copyright />
      </div>
    </footer>
  );
};

export default Footer;
